<script lang="ts" setup>
interface Props {
    modelValue: number;
    slideCount: number;
}

interface Emits {
    (e: "update:modelValue", value: number): void 
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();
const intervalId = ref<NodeJS.Timer>();

function cycle() {
    if (props.modelValue >= props.slideCount - 1) {
        emits("update:modelValue", 0);
        return;
    }

    emits("update:modelValue", props.modelValue + 1);
}

function startInterval() {
    if (intervalId.value) {
        clearInterval(intervalId.value as unknown as number);
    }

    intervalId.value = setInterval(cycle, 6000);
}

function selectSlide(slide: number) {
    startInterval();
    emits("update:modelValue", slide);
}

onMounted(() => {
    startInterval();
})
</script>

<template>
    <div class="relative h-full">
        <slot></slot>
        <div v-if="slideCount > 1" class="absolute left-2/4 -translate-x-1/2 h-4 bottom-4 flex">
            <div
                v-for="idx in slideCount"
                :key="idx"
                :class="{ 'bg-white': idx === modelValue + 1 }"
                class="carousel-indicator block w-3 h-3 lg:w-4 lg:h-4 lg border-solid border-grey-600 border-x border-y rounded-full mx-[0.375rem] cursor-pointer"
                @click="selectSlide(idx - 1)"
            ></div>
        </div>
    </div>
</template>
